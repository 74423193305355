@import "../../mixins.scss";

@keyframes fadeInServiceVideoPopup {
  from {
    opacity: 0;
    max-width: 0;
  }
  to {
    opacity: 1;
    max-width: 100vw;
  }
}

.services-video-popup {
  animation: fadeInServiceVideoPopup 1.5s ease;
  width: 100vw;
  height: 100vh;
  max-width: 1000vw;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #111111bb;
  box-shadow: 0 0 60px 5px #000000;
  @include flex;

  .inner-sect {
    position: relative;

    iframe {
      width: 900px;
      height: auto;
      aspect-ratio: 1.78/1;

      @media (max-width: 950px) {
        width: 700px;
      }

      @media (max-width: 750px) {
        width: 350px;
      }
    }

    .icon-box {
      width: 40px;
      height: 40px;
      border: none;
      border-radius: 100%;
      background: #fff;
      position: absolute;
      right: -17px;
      // right: -45px;
      top: -17px;
      // top: -45px;
      z-index: 9999;
      @include flex;
      border: 1px solid var(--primary-color);
      transition: var(--basic);
      cursor: pointer;
      .icon {
        font-size: 32px;
        color: var(--primary-color);
        transition: var(--advance);
      }

      &:hover {
        // transform: scale(1.1);
        .icon {
          transform: rotate(90deg);
        }
      }
    }
  }
}
