@import "../../mixins.scss";

.services {
    .video-pop-btn {
      @include button($w: 340px);
      margin: 0 auto 40px auto;
    }

  .pro-massage-img {
    width: 320px;
    height: auto;
    position: relative;
    right: 0;
    left: 0;
    top: 0;
    margin: 0 auto 15px auto;

    @media (max-width: 600px) {
      width: 280px;
      margin-top: -40px;
    }
  }

  padding: 0 10%;

  .main-heading {
    @include heading2();
    color: var(--primary-text);
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .up-desc1 {
    @include para();
    color: var(--primary-text);
    line-height: 28px;

    span {
      display: block;
      @include para();
      color: var(--primary-text);
      line-height: 28px;
    }
  }
  .up-desc2 {
    margin-top: 15px;
    @include para($fw: 500);
    color: var(--primary-text);
    text-transform: uppercase;
    line-height: 28px;

    span {
      display: block;
      @include para();
      color: var(--primary-text);
      line-height: 28px;
    }
  }

  .service-section {
    @include flex();
    margin: 30px auto;
    .image {
      flex: 1.6;
    }

    .content {
      flex: 1;
      margin: 120px 0 0 -80px;
      h3 {
        @include heading2();
        color: var(--primary-text);
      }
      h2 {
        @include heading2();
        color: var(--primary-text);
      }
      h2 a {
        @include heading2();
        color: var(--primary-text);
      }
      .desc {
        @include para();
        color: var(--primary-text);
      }
      ul {
        margin: 8px 0;
        li {
          @include para($fw: 400);
          color: var(--primary-text);
          position: relative;
          margin-left: 15px;
          &:before {
            content: "";
            position: absolute;
            top: 8px;
            left: -15px;
            height: 8px;
            width: 8px;
            background-color: var(--primary-color);
            border: none;
            border-radius: 100%;
          }
        }
      }

      .styles {
        @include para();
        color: var(--primary-text);
      }

      button {
        @include button($w: 240px);
        margin-top: 15px;
      }
    }

    @media (min-width: 1200px) {
      &:nth-of-type(1) {
        .content {
          margin: 170px 0 0 -80px;
        }
      }
    }
  }

  .reverse {
    flex-direction: row-reverse;
    .content {
      margin: 120px -80px 0 0;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 5%;
    .service-section {
      flex-direction: column;
      margin: 70px auto;
      .content {
        margin: 40px 0 0 0;
        button {
          @include button($w: 200px);
          margin: 15px unset;
          a {
            font-size: 1.4rem;
            padding: 6px;
          }
        }
      }
    }
  }
}
